<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout fill-height column>
		<w-flex shrink>
			<v-toolbar dense>
				<w-search-input v-model="search" :placeholder="$t('holding.admin-team.search_for_team_member')" pr-2 />
				<v-spacer />
				<v-divider vertical class="ml-2" />
				<w-btn icon="add" :mini="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" @click="openAddDialog()">{{
					$t('holding.admin-team.add_member')
				}}</w-btn>
				<w-dialog
					v-model="showAddDialog"
					:title="$t('holding.admin-team.add_member')"
					no-padding
					:fullscreen="$vuetify.breakpoint.xsOnly"
					max-width="60vw"
					@close="oncloseAddDialog()"
				>
					<HoldingUserCreateForm ref="holdingUserCreateForm" :in-dialog="true" @canceled="showAddDialog = false" @created="refreshList()" />
				</w-dialog>
			</v-toolbar>
		</w-flex>
		<w-flex scroll-y>
			<w-layout column>
				<w-flex v-t="'holding.admin-team.waiting_invitations'" ml-4 my-4 shrink title />
				<w-flex shrink scroll-y mx-4>
					<v-data-table
						class="elevation-1"
						:headers="headersNotConnected"
						:items="usersNotConnected"
						:loading="usersNotConnectedLoading"
						:pagination="paginationUsersNotConnected"
						:rows-per-page-items="rowsPerPageItems"
						:total-items="paginationUsersNotConnected.totalItems"
						@update:pagination="onPaginationNotActivatedUpdated($event)"
					>
						<template v-slot:items="{ item: user }">
							<tr class="pointer" @click="selectUser(user)">
								<td v-html="$highlightMatching(user.firstname, search)" />
								<td v-html="$highlightMatching(user.lastname, search)" />
								<td v-html="$highlightMatching(user.email, search)" />
								<td class="text-xs-center" @click.stop>
									<w-switch v-model="user.is_holding_admin" :disabled="currentUser.id == user.id" @input="updateUserRole(user)" />
								</td>
								<td @click.stop>
									<w-btn v-if="!user.did_connect && user.invited_at" :disabled="currentUser.id == user.id" flat icon="redo" @click="sendMail(user)">{{
										$t('holding.admin-team.reinvit')
									}}</w-btn>
									<w-btn v-if="!user.invited_at" :disabled="currentUser.id == user.id" flat icon="add" @click="sendMail(user)">{{
										$t('holding.admin-team.invit')
									}}</w-btn>
								</td>
								<td @click.stop>
									<w-btn-delete :disabled="currentUser.id == user.id" flat mini @click="deleteUser(user, 'not-activated')" />
								</td>
							</tr>
						</template>
					</v-data-table>
				</w-flex>
				<w-flex v-t="'holding.admin-team.members'" ma-4 shrink title />
				<w-flex mb-4 mx-4 scroll-y shrink>
					<v-data-table
						class="elevation-1"
						:headers="headers"
						:items="users"
						:loading="usersLoading"
						:pagination="paginationUsers"
						:rows-per-page-items="rowsPerPageItems"
						:total-items="paginationUsers.totalItems"
						@update:pagination="onPaginationUpdated($event)"
					>
						<template v-slot:items="{ item: user }">
							<tr class="pointer" @click.stop="selectUser(user)">
								<td v-html="$highlightMatching(user.firstname, search)" />
								<td v-html="$highlightMatching(user.lastname, search)" />
								<td v-html="$highlightMatching(user.email, search)" />
								<td @click.stop>
									<w-switch v-model="user.is_holding_admin" :disabled="currentUser.id == user.id" @input="updateUserRole(user)" />
								</td>
								<td @click.stop>
									<w-btn-delete :disabled="currentUser.id == user.id" flat mini @click="deleteUser(user, 'connected')" />
								</td>
							</tr>
						</template>
					</v-data-table>
				</w-flex>
			</w-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import HoldingUserModuleGuard from '@/mixins/ModulesGuards/Holding/HoldingUserModuleGuard'

export default {
	name: 'HoldingTeamList',
	components: {
		HoldingUserCreateForm: () => ({
			component: import('@/components/Holding/HoldingUserCreateForm')
		})
	},
	mixins: [HoldingUserModuleGuard],
	inject: ['holdingContext'],
	props: {},
	data: function () {
		return {
			headers: [
				{
					sortable: true,
					text: this.$tc('holding.admin-team.firstname', 1),
					value: 'firstname'
				},
				{
					sortable: true,
					text: this.$tc('holding.admin-team.lastname', 1),
					value: 'lastname'
				},
				{
					sortable: true,
					text: this.$tc('holding.admin-team.email', 1),
					value: 'email'
				},
				{
					sortable: false,
					text: this.$tc('holding.admin-team.admin_welyb', 1)
				},
				{
					sortable: false,
					text: '',
					width: '40px'
				}
			],
			headersNotConnected: [
				{
					sortable: true,
					text: this.$tc('holding.admin-team.firstname', 1),
					value: 'firstname'
				},
				{
					sortable: true,
					text: this.$tc('holding.admin-team.lastname', 1),
					value: 'lastname'
				},
				{
					sortable: true,
					text: this.$tc('holding.admin-team.email', 1),
					value: 'email'
				},
				{
					sortable: false,
					text: this.$tc('holding.admin-team.admin_welyb', 1)
				},
				{
					sortable: false,
					text: ''
				},
				{
					sortable: false,
					text: '',
					width: '40px'
				}
			],
			paginationUsers: {
				descending: false,
				page: 1,
				rowsPerPage: 10,
				sortBy: 'firstname',
				totalItems: 0
			},
			paginationUsersNotConnected: {
				descending: false,
				page: 1,
				rowsPerPage: 10,
				sortBy: 'firstname',
				totalItems: 0
			},
			rowsPerPageItems: [10, 25, 50, 100],
			search: '',
			searchTimeout: null,
			showAddDialog: false,
			users: [],
			usersNotConnected: [],
			usersNotConnectedLoading: true,
			usersLoading: true
		}
	},
	computed: {
		holdingId: function () {
			return this.holdingContext?.holding_id
		}
	},
	watch: {
		search: {
			handler: function () {
				clearTimeout(this.searchTimeout)
				this.resetPagination()
				this.searchTimeout = setTimeout(() => {
					this.loadUsersNotActivated()
					this.loadUsers()
				}, 1000)
			}
		}
	},
	mounted: function () {
		this.loadUsersNotActivated()
		this.loadUsers()
	},
	methods: {
		deleteUser: function (user, listName) {
			this.$dialog
				.warning({
					text: this.$t('holding.admin-team.actions.are_you_sure', { username: user.username }),
					title: this.$t('holding.admin-team.actions.delete'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.service.deleteHoldingUser(this.holdingId, user.id).then(() => {
							if (listName === 'not-activated') {
								this.loadUsersNotActivated()
							} else {
								this.loadUsers()
							}
							this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('holding.admin-team.user_deleted'))
						})
					}
				})
		},
		loadUsers: function () {
			const params = { page: this.paginationUsers.page, search: this.search, rowsPerPage: this.paginationUsers.rowsPerPage }
			if (this.paginationUsers.sortBy) {
				params.sort = (this.paginationUsers.descending ? '-' : '+') + this.paginationUsers.sortBy
			}
			this.usersLoading = true
			this.service.getHoldingUsers(this.holdingId, params).then(users => {
				this.usersLoading = false
				this.users = users.data
				this.paginationUsers.page = users.pagination.currentPage
				this.paginationUsers.rowsPerPage = users.pagination.perPage
				this.paginationUsers.totalItems = users.pagination.total
			})
		},
		loadUsersNotActivated: function () {
			const params = {
				page: this.paginationUsersNotConnected.page,
				not_connected: true,
				search: this.search,
				rowsPerPage: this.paginationUsersNotConnected.rowsPerPage
			}
			if (this.paginationUsersNotConnected.sortBy) {
				params.sort = (this.paginationUsersNotConnected.descending ? '-' : '+') + this.paginationUsersNotConnected.sortBy
			}

			this.usersNotConnectedLoading = true
			this.service.getHoldingUsers(this.holdingId, params).then(usersNotConnected => {
				this.usersNotConnectedLoading = false
				this.usersNotConnected = usersNotConnected.data
				this.paginationUsersNotConnected.page = usersNotConnected.pagination.currentPage
				this.paginationUsersNotConnected.rowsPerPage = usersNotConnected.pagination.perPage
				this.paginationUsersNotConnected.totalItems = usersNotConnected.pagination.total
			})
		},
		openAddDialog: function () {
			this.showAddDialog = true
		},
		oncloseAddDialog: function () {
			this.$refs.holdingUserCreateForm.resetForm()
		},
		onPaginationNotActivatedUpdated: function (paginationData) {
			Object.assign(this.paginationUsersNotConnected, paginationData)
			this.loadUsersNotActivated()
		},
		onPaginationUpdated: function (paginationData) {
			Object.assign(this.paginationUsers, paginationData)
			this.loadUsers()
		},
		refreshList: function () {
			this.resetPagination()
			this.loadUsersNotActivated()
			this.loadUsers()
		},
		resetPagination: function () {
			this.paginationUsersNotConnected.page = 1
			this.paginationUsers.page = 1
		},
		selectUser: function (user) {
			this.appService.goTo({
				name: 'holding-team-user',
				params: {
					userId: user.id
				}
			})
		},
		sendMail: function (user) {
			if (!user.invited_at || (!user.did_connect && user.invited_at)) {
				this.service.sendMail(this.holdingId, user.id).then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('actions.mail_sent'))
					this.usersNotConnected.map(item => {
						if (item.id == user.id) {
							item.invited_at = Date.now()
						}
						return item
					})
				})
			}
		},
		updateUserRole: function (user) {
			const data = { role_key: user.is_holding_admin ? 'admin' : 'collaborator' }
			this.service.updateHoldingUser(this.holdingId, user.id, data).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('holding.admin-team.role_change_success'))
			})
		}
	}
}
</script>
